html, 
body {
    height: 100%;
    margin:0px;
    padding: 0px;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

