body, html { 
}
p {
    font-weight: normal;
}
.x-bold-txt {
    font-weight: 900;
}
.page-wrapper {
    font-family: 'Poppins', sans-serif;
    background-color: #000000;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
}

/* TOP NAVIGATION */
.header-nav {
  position: absolute;
  z-index: 1000;
  top:0px;
  width: 100%;
}
.menu-icon-container {
    position: absolute;
    left: 20px;
    top:15px;
    padding: 0;
    margin:0;
    
}
.menu-icon {

    width: 150px;
    height: 50px;
    
}
.logo {
    position: absolute;
    left: 65px;
    width:220px;
    padding-top:25px;
    margin-left:20px;
}
.top-nav {
    padding-top:20px;
    padding-right: 30px;
    text-align: right;
    cursor: pointer;
}
/* END TOP NAVIGATION */

.barrows-border-color {
    border-color: #ff2363;
}
.barrows:focus {
    outline: none !important;
}
.barrows {
    background-color: #ff2363
}
.walmart {
    background-color: #05a2fd;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}
.walmart:focus {
    outline: none !important; 
}
.walmart-border-color {
    border-color: #05a2fd;
}
.tesco {
    background-color: #e62432;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}
.tesco:focus {
    outline: none !important; 
}
.tesco-border-color {
    border-color: #e62432;
}
.shoprite {
    background-color: #f5b827;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}
.shoprite-border-color {
    border-color: #f5b827;
}
.shoprite:focus {
    outline: none !important;
}
.barrows-text {
    color: #ff2363
}
.walmart-text {
    color: #05a2fd;
    -webkit-transition: color 1000ms linear;
    -ms-transition: color 1000ms linear;
    transition: color 1000ms linear;
}
.tesco-text {
    color: #e62432;
    -webkit-transition: color 1000ms linear;
    -ms-transition: color 1000ms linear;
    transition: color 1000ms linear;
}
.shoprite-text {
    color: #f5b827;
    -webkit-transition: color 1000ms linear;
    -ms-transition: color 1000ms linear;
    transition: color 1000ms linear;
}
.barrows-dot, .walmart-dot, .shoprite-dot, .tesco-dot {
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-transition: background 1000ms linear;
    -moz-transition: background 1000ms linear;
    -o-transition: background 1000ms linear;
    -ms-transition: background 1000ms linear;
    transition: background 1000ms linear;
}
.barrows-dot {

    background-image: url("https://assets.barrowsconnectedstore.com/home/images/barrows-dot.png");
}
.walmart-dot {

    background-image: url("https://assets.barrowsconnectedstore.com/home/images/walmart-dot.png");
}
.tesco-dot {
    background-image: url("https://assets.barrowsconnectedstore.com/home/images/tesco-dot.png");
}
.shoprite-dot {
    background-image: url("https://assets.barrowsconnectedstore.com/home/images/shoprite-dot.png");
}
.header-grid {
    display: grid;    
}
.video-container {
    grid-column: 1/8;
}
.video-overlay {
    grid-column: 1/8;
     width: 100%;
     height: 100%;
     content: '';
     background: radial-gradient(circle at 50% 2%, rgba(0, 0, 0, 0.8) 0%, rgb(0, 0, 0, .9) 40%, rgba(0, 0, 0, .9) 20%);
}
.video-container, 
.video-overlay, 
.callout-container, 
.header-nav, 
.callout-box-1, 
.callout-box-2, 
.callout-box-3,
.headline-content {
    grid-row: 1/2;
    
}
/* THE HEADLINE TEXT AND CALL OUT BOXES ARE PLACED OVER THE VIDEO */
.headline-content, .callout-box-1, .callout-box-2, .callout-box-3  {
    position: relative;
    z-index: 1000;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
}
/* IMAGE GALLERY */
.gallery-section {
    grid-column: 1/8;

}
.gallery-images {
    display:flex;
    width: auto;
    align-items: center;
    margin-top:50px;
    margin-left:50px;
    margin-right: 50px;
    align-items: flex-start;
    align-self: flex-start;

}
.gallery-image {
    width: 100%;
    height: auto;
    align-items: start;
}
.col-1 {
    align-items: flex-start;
    width: 60%;
}
.col-2 {
    width: 41%;
    margin-left:20px;
}
.row-1-col-1 {
    width: 50%;
}
.row-2-col-2 {
    width: 50%;
    margin-left:20px;
}
/* END IMAGE GALLERY */

/* RETAIL MEDIA */
.retail-media {
    margin-top:50px;
    min-height: 2250px;
 }
.rm-content {
    display:flex;
    width: auto;
    height: auto;
    align-items: center;
    margin-top:50px;
    align-items: flex-start;
    align-self: flex-start;
    color: white;
}
.rmn-col-1-content {
    align-items: flex-start;
    width: 50%;
}
.rmn-col-2-image {
    width: 50%;
}
.rmn-col-1-image {
    width: 50%;
    align-items: center;
    margin:0px;
    padding:0px;
}
.rmn-col-2-content {
    align-items: flex-start;
    width: 50%;
    padding-right: 20px;
    padding-left:30px;
}
.rmn-col-1-content {
    width: 60%;
    padding-right: 30px;
}

.rmn-quote-block {
    display: block;
    text-align: center;
    margin-top:50px;
    width: 90%;
    color:#dddddd;
    margin-right: auto;
    margin-left: auto;
    font-family: 'Times New Roman', Times, serif;
}
.rmn-quote {
    line-height: 1.55em;
    font-style: italic;
}
.rmn-quote-author-name {
    margin-top: 40px;
    font-weight: bold;
    display: block;
}
.rmn-quote-author-title {
    font-size:.6em;
}
.rmn-overview {
    margin-top: 75px;
    margin-bottom: 75px;
    margin-right: auto;
    margin-left: auto;
    color: white;
    width: 90%;
    text-align: left;
}
.sub-section-headline {
    font-weight: bold;
    font-size: 1.2em;
}
.sub-section-article {
    margin-top:25px;
    font-size: .6em;
    line-height: 2em;
}
.numbered-image-bubble-right {
    display: flex;
    border-radius: 50px;
    width: 75px;
    height: 75px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    position: absolute;
    top:-15px;
    left: 160px;
}
.numbered-image-bubble-left {
    display: flex;
    border-radius: 50px;
    width: 75px;
    height: 75px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    position: absolute;
    top:20px;
    left:30px;
}
.sub-section-image-container-left {
    width: 100%;
    padding: 10px;
    margin: 0px;
}
.sub-section-image-container-right {
    width: 100%;
    padding: 10px;
    margin: 0px;
}
.numbered-image-bubble-right {
    width: 50px;
    height: 50px;
    top: -10px;
    left: 10px;
}
.numbered-image-bubble-left {
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    position: absolute;
    width: 50px;
    height: 50px;
    top: 20px;
    left: 30px;
}
/* END RETAIL MEDIA  */


/* CONTACT US SECTON */
.contact-form-container {
    display: block;
}
.gray-to-black-full-span {
    display: block;
    height: 400px;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(51,51,51,1) 100%);
}
.contact-us {
    margin-top: 50px;
}
.contact-form {
    padding: 20px;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
}
.form-input-background {
    background-color: #727272;
}
.form-required-message {
    font-weight: normal;
    font-size: .75em;
}
.form-error-message {
    color: #e62432;
    font-style: italic;
    font-size: .9em;
}
.form-error-highlight:focus {
    border: 3px solid #e62432;

}
/* CONTACT US SECTON */

/* THESE ARE THE HEADLINES IN BETWEEN EACH SECTION.  THERE IS TEXT AND A BAR WITH A PILL */
.section-headline {
    font-size: 25px;
    border-bottom: .5px solid #ffffff;
    height: 59px;
    position: relative;
    margin-left: 25px;
    margin-right: 25px;
}
.headline-pill {
    margin-top:16px;
    width: 150px;
    height: 10px;
    border-radius: 500px;
}

/* NETWORK AND PARTNER SECTION */
.partner-metrics {
    grid-column: 1/5;
    border: 1px solid #656565;
    border-right: 0px;
    border-left: 0px;
    height: 130px;
}
.metric-top-number {
    font-size: 75px;
    font-weight: 900;
    text-align: center;
    margin-bottom: -60px;
}
.metric-bottom-text {
    text-align: center;
    font-size: 14px;
    line-height: 90px;
}

.brand-section {
    display: grid;
    grid-column: 1/5;
    margin-top: 150px; 
}
.logos-section {
    grid-column:1/5;
    margin-top: 100px;
    margin-bottom: 100px;
}
/* NETWORK AND PARTNER SECTION */



/* THESE CALL OUT STYLES DO NOT NEED TO CHANGE ON MEDIA QUERY */
.header-grid {
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 20px;
}
.headline-content {
    margin-top:100px;
    grid-column: 2/4;
}
.callout-box {
    margin-top:420px;
}
.callout-box-1 {
    grid-column: 2/3;
    border-radius: 20px;
}
.callout-box-1-card {
    padding-top:0px;
    border-radius: 20px;
    font-weight: 500;
    background: linear-gradient(to bottom, #000000 0%,#333333 100%); 
}
.callout-box-1-pill {
    width: 8px;
    border-radius: 500px;
    float: left;
}
.callout-box-1-content {
    padding-left: 50px;
}
.callout-box-2 {
    grid-column: 3/4;
    border-radius: 20px;
    padding:20px;
}
.callout-box-3  {
    grid-column: 4/5;
    border-radius: 20px;
}
.callout-box-3-card {
    border-radius: 20px;
    background: linear-gradient(to bottom, #000000 0%,#333333 100%); 
}
@media screen and (min-width: 300px) and (max-width: 780px) {
    .header-grid {
        height: 300px;
        grid-column-gap: 0px;
    }
    .headline-content {
        font-weight: 900;
        font-size: 25px;
        line-height: 30px;
        grid-column: 1/5;
        margin-left: 20px;
        padding: 0;
        margin-top: 60px;
    }
    .section-headline {
        font-size: 20px;
    }

    .headline-pill {
        margin-top: 24px;
        width: 80px;
        height: 10px;
    }
    .logos-section {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .metric-top-number {
        font-size: 52px;
        margin-bottom: -52px;
    }
    .partner-metrics {
        height: 95px;
    }
   .callout-box-1, .callout-box-2, .callout-box-3 {
    display: none;
   }
    .brand-section {
        margin-top: 0px;
        height: 420px;
    }
    .gallery-images {
        margin-left: 20px;
        margin-right: 20px;
    }
    .row-2-col-2 {
        display: none;
    }
    .rmn-quote-block {
        width: 95%;
    }
    .rmn-quote-author-name {
        margin-top: 16px;
        font-size: 18px;
    }
    .rmn-overview {
        width: 95%;
        margin-top: 45px;
        margin-bottom: 45px;
        font-size: .8em;
    }
    .rmn-col-1-content {
        padding-right: 4px;
        font-size: .7em;
    }
    .rmn-col-2-content {
        padding-left: 0px;
        padding-right: 5px;
        font-size: .8em;
    }
    .sub-section-headline {
        font-size: 1em;
    }
    .numbered-image-bubble-left {
        width: 40px;
        height: 40px;
        top: 9px;
        left: 18px;
    }
    .numbered-image-bubble-right {
        width: 40px;
        height: 40px;
        top: -4px;
        left: 3px;
    }
    .retail-media {
        min-height: 1520px;
    }
    
    .contact-us  {
        margin-top: 35px;
        font-size: .8em;
    }
    .contact-us div {
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .menu-icon  {
        margin-right: 10px;
    }
    .logo {
        width: 130px;
        padding-top: 27px;
        margin-left: 0px;
    }
    .top-nav {
        font-size: .7em;
        padding-top: 25px;
        padding-right: 19px;
        text-align: right;
    }

}
@media screen and (min-width: 300px) and (max-width: 500px) {
    .section-headline {
        font-size: 15px;
        height: 52px;
    }
    .brand-section {
        height: 400px;
    }
    .metric-bottom-text {
        font-size: 8px
    }
    .retail-media {
        min-height: 2200px;
    }
    .rmn-col-1-content, .rmn-col-2-content {
        font-size: 1em;
    }
}
@media screen and (min-width: 500px) and (max-width: 600px) {
    .metric-bottom-text {
        font-size: 10px
    }
}
@media screen and (min-width: 600px) and (max-width: 780px) {
    .metric-bottom-text {
        font-size: 10px
    }
    .gallery-section  {
        margin-top: 100px;
    }
}
@media only screen and (min-width: 780px) and (max-width: 1024px) {
    .header-grid {
        height: 700px;
    }
    .headline-content {
        font-weight: 900;
        font-size: 35px;
        line-height: 35px;
    }
    .callout-box-1 { 
        width: 234px;
        height: 170px;
    }
    .callout-box-1-card {
        width: 226px;
        height: 162px;
    }
    .callout-box-1-pill {
        height: 90px;
        margin-left:15px;
        margin-top:36px;
    }
    .callout-box-1-content {
        font-size: 24px;
        line-height: 24px;
        padding-top: 44px;
    }
    .callout-box-2 {
        width: 234px;
        height: 170px;
        padding-top:50px;
    }
    .callout-box-2-content {
        font-size: 24px;
        line-height: 24px;
    }
    .callout-box-3 {
        width: 234px;
        height: 170px;
    }
    .callout-box-3-card {
        width: 226px;
        height: 162px;
        padding:20px;
        padding-top:35px;
        margin-top:10px;
    }
    .callout-box-3-content {
        font-size: 24px;
        line-height: 24px;
    }
    .brand-section {
        margin-top: 0px;
        height: 750px;
    }
    
}
@media only screen and (min-width: 1024px) and (max-width: 1248px) {
    .headline-content {
        margin-top:115px;
        font-size: 40px;
        line-height: 40px;
    }
    .callout-box {
        margin-top: 483px;
    }
    .callout-box-1 { 
        width: 269px;
        height: 196px;
    }
    .callout-box-1-card {
        width: 260px;
        height: 186px;
    }
    .callout-box-1-pill {
        height: 104px;
        margin-left:15px;
        margin-top:41px;
    }
    .callout-box-1-content {
        font-size: 24px;
        line-height: 24px;
        padding-top: 60px;
    }
    .callout-box-2 {
        width: 269px;
        height: 196px;
        padding-top:60px;
    }
    .callout-box-2-content {
        font-size: 24px;
        line-height: 24px;
    }
    .callout-box-3 {
        width: 269px;
        height: 196px;
    }
    .callout-box-3-card {
        width: 260px;
        height: 186px;
        padding:20px;
        padding-top:40px;
        margin-top:10px;
        margin-left:10px;
    }
    .callout-box-3-content {
        font-size: 24px;
        line-height: 24px;
    }
    .brand-section {
        margin-top: 100px; 
    }
    .logos-section {
        padding:30px;
    }
    .partner-metrics {
        height: 150px;
    }
    .metric-top-number {
        font-size: 86px;
        margin-bottom: -60px;
    }
    .metric-bottom-text {
        text-align: center;
        font-size: 16px;
        line-height: 90px;
    }
    .section-headline {
        font-size: 29px;
        height: 65px;
    }
    .brand-section {
        height: 850px;
    }
    .retail-media {
        min-height: 2450px;
    }
    .numbered-image-bubble-left {
        left: 70px;
        top: 50px;
    }
    .numbered-image-bubble-right {
        left: 30px;
    }
}

@media only screen and (min-width: 1248px) and (max-width: 1472px) {
    .headline-content {
        margin-top:132px;
        font-size: 46px;
        line-height: 46px;
    }
    .callout-box {
        margin-top: 555px;
    }
    .callout-box-1 { 
        width: 308px;
        height: 225px;
    }
    .callout-box-1-card {
        width: 299px;
        height: 214px;
    }
    .callout-box-1-pill {
        height: 120px;
        margin-left:15px;
        margin-top:48px;
    }
    .callout-box-1-content {
        font-size: 28px;
        line-height: 28px;
        padding-top: 69px;
    }
    .callout-box-2 {
        width: 308px;
        height: 225px;
        padding-top:69px;
    }
    .callout-box-2-content {
        font-size: 28px;
        line-height: 28px;
    }
    .callout-box-3 {
        width: 308px;
        height: 225px;
    }
    .callout-box-3-card {
        width: 299px;
        height: 216px;
        padding:20px;
        padding-top:40px;
        margin-top:10px;
        margin-left:10px;
    }
    .callout-box-3-content {
        font-size: 28px;
        line-height: 28px;
    }
    .brand-section {
        margin-top: 150px;
    }
    .partner-metrics {
        height: 150px;
    }
    .metric-top-number {
        font-size: 86px;
        margin-bottom: -60px;
    }
    .metric-bottom-text {
        text-align: center;
        font-size: 16px;
        line-height: 90px;
    }
    .section-headline {
        font-size: 33px;
        height: 71px;
    }
    .brand-section {
        height: 925px;
    }
    .retail-media {
        min-height: 2550px;
    }
    .numbered-image-bubble-left {
        left: 80px;
        top:40px;
        width: 65px;
        height: 65px;
    }
    .numbered-image-bubble-right {
        left: 20px;
        top:-20px;
        width: 65px;
        height: 65px;
    }
    .rmn-col-1-content, .rmn-col-2-content {
        font-size: .9em;
    }
}
@media only screen and (min-width: 1472px) and (max-width: 1696px) {
    .headline-content {
        margin-top:152px;
        font-size: 53px;
        line-height: 53px;
    }
    .callout-box {
        margin-top: 638px;
    }
    .callout-box-1 { 
        width: 354px;
        height: 259px;
    }
    .callout-box-1-card {
        width: 344px;
        height: 246px;
    }
    .callout-box-1-pill {
        height: 138px;
        margin-left:15px;
        margin-top:55px;
    }
    .callout-box-1-content {
        font-size: 32px;
        line-height: 32px;
        padding-top: 75px;
    }
    .callout-box-2 {
        width: 354px;
        height: 259px;
        padding-top:79px;
    }
    .callout-box-2-content {
        font-size: 32px;
        line-height: 32px;
    }
    .callout-box-3 {
        width: 354px;
        height: 259px;
    }
    .callout-box-3-card {
        width: 344px;
        height: 250px;
        padding:20px;
        padding-top:56px;
        margin-top:10px;
        margin-left:10px;
    }
    .callout-box-3-content {
        font-size: 32px;
        line-height: 32px;
    }
    .brand-section {
        margin-top: 150px;
    }
    .partner-metrics {
        height: 173px;
    }
    .metric-top-number {
        font-size: 99px;
        margin-bottom: -60px;
    }
    .metric-bottom-text {
        text-align: center;
        font-size: 18px;
        line-height: 90px;
    }
    .section-headline {
        font-size: 33px;
        height: 71px;
    }
    .brand-section {
        height: 1000px;
    }
    .retail-media {
        min-height: 2700px;
    }
    .numbered-image-bubble-left {
        left: 110px;
        top:60px;
        width: 65px;
        height: 65px;
    }
    .numbered-image-bubble-right {
        left: 20px;
        top:-20px;
        width: 65px;
        height: 65px;
    }
    .rmn-col-1-content, .rmn-col-2-content {
        font-size: 1em;
    }
   
}
@media only screen and (min-width: 1696px) and (max-width: 1920px) {
    .headline-content {
        margin-top:175px;
        font-size: 61px;
        line-height: 61px;
    }
    .callout-box {
        margin-top: 733px;
    }
    .callout-box-1 { 
        width: 407px;
        height: 298px;
    }
    .callout-box-1-card {
        width: 396px;
        height: 283px;
    }
    .callout-box-1-pill {
        height: 159px;
        margin-left:15px;
        margin-top:63px;
    }
    .callout-box-1-content {
        font-size: 37px;
        line-height: 37px;
        padding-top: 85px;
    }
    .callout-box-2 {
        width: 407px;
        height: 298px;
        padding-top:90px;
    }
    .callout-box-2-content {
        font-size: 37px;
        line-height: 37px;
    }
    .callout-box-3 {
        width: 407px;
        height: 298px;
    }
    .callout-box-3-card {
        width: 396px;
        height: 290px;
        padding:20px;
        padding-top:56px;
        margin-top:10px;
        margin-left:10px;
    }
    .callout-box-3-content {
        font-size: 37px;
        line-height: 37px;
        padding-top:10px;
    }
    .brand-section {
        margin-top: 150px;
    }
    .partner-metrics {
        height: 199px;
    }
    .metric-top-number {
        font-size: 114px;
        margin-bottom: -60px;
    }
    .metric-bottom-text {
        text-align: center;
        font-size: 21px;
        line-height: 90px;
    }
    .section-headline {
        font-size: 38px;
        height: 78px;
    }
    .brand-section {
        height: 1000px;
    }
    .retail-media {
        min-height: 3050px;
    }
    .numbered-image-bubble-left {
        left: 130px;
        top:60px;
        width: 75px;
        height: 75px;
    }
    .numbered-image-bubble-right {
        left: 20px;
        top:-20px;
        width: 75px;
        height: 75px;
    }
    .rmn-col-1-content, .rmn-col-2-content {
        font-size: 1em;
    }

}
@media only screen and (min-width: 1920px)  {
    .headline-content {
        margin-top:201px;
        font-size: 70px;
        line-height: 70px;
    }
    .callout-box {
        margin-top: 843px;
    }
    .callout-box-1 { 
        width: 468px;
        height: 343px;
    }
    .callout-box-1-card {
        width: 455px;
        height: 334px;
    }
    .callout-box-1-pill {
        height: 183px;
        margin-left:15px;
        margin-top:83px;
    }
    .callout-box-1-content {
        font-size: 43px;
        line-height: 43px;
        padding-top: 105px;
    }
    .callout-box-2 {
        width: 468px;
        height: 343px;
        padding-top:105px;
    }
    .callout-box-2-content {
        font-size: 43px;
        line-height: 43px;
    }
    .callout-box-3 {
        width: 468px;
        height: 343px;
    }
    .callout-box-3-card {
        width: 458px;
        height: 334px;
        padding:20px;
        padding-top:56px;
        margin-top:10px;
        margin-left:10px;
    }
    .callout-box-3-content {
        font-size: 43px;
        line-height: 43px;
        padding-top:20px;
    }

    .brand-section {
        margin-top: 150px;
    }
    .partner-metrics {
        height: 239px;
    }
    .metric-top-number {
        font-size: 137px;
        margin-bottom: -60px;
    }
    .metric-bottom-text {
        text-align: center;
        font-size: 25px;
        line-height: 90px;
    }
    .section-headline {
        font-size: 46px;
        height: 91px;
    }

    .brand-section {
        height: 1100px;
    }
    .retail-media {
        min-height: 3550px;
    }
    .numbered-image-bubble-left {
        left: 130px;
        top:60px;
        width: 75px;
        height: 75px;
    }
    .numbered-image-bubble-right {
        left: 20px;
        top:-20px;
        width: 75px;
        height: 75px;
    }
    .rmn-col-1-content, .rmn-col-2-content {
        font-size: .8em;
    }
}


/*

.sub-section-headline {
    font-size:1em;
}
.sub-section-article {
    font-size: .5em;
}

.brand-section {
    display: grid;
    grid-column: 1/8;
    margin-top: 150px;
    
}
.logos-section {
    grid-column:1/8;
    margin-top:200px;
   margin-bottom: 175px;
}
.partner-metrics {
    grid-column: 1/8;
    border: 1px solid #656565;
    border-right: 0px;
    border-left: 0px;
    height: 310px;
    margin-top: 50px;
}
.metric-top-number {
  font-size: 175px;
  font-weight: 900;
  text-align: center;
  margin-bottom: -60px;
}
.metric-bottom-text {
    text-align: center;
    font-size: 28px;
    line-height: 36px;
}

.section-headline {
    margin-top: 40px;
    margin-right: 100px;
    margin-left: 100px;
    font-size: 35px;
    border-bottom: .5px solid #ffffff;
    height: 75px;
    position: relative;
}

.headline-pill {
    margin-top:16px;
    width: 150px;
    height: 10px;
    border-radius: 500px;
}


.retailer-logos-image {
    display: block;
    height: 100%;
}

.headline-content {
    margin-top:125px;
    grid-column: 3/6;
    font-weight: 900;
    font-size: 60px;
    line-height: 60px;
}
.callout-box-1 {
    grid-column: 3/4;
}
.callout-box-2 {
    grid-column: 4/5;
}
.callout-box-3 {
    grid-column: 5/6;
}

.header-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr) repeat(3, 390px) repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 0px;

}


@media only screen and (min-width: 330px) and (max-width: 1279px) {
    .header-grid {
        grid-template-columns: repeat(2, 50px) repeat(3, 320px) repeat(2, 50px);
        grid-column-gap: 0px;

    }
    .headline-content {
        margin-top:125px;
        font-weight: 900;
        font-size: 45px;
        line-height: 45px;
    }
    .callout-box-1 { 
        margin-top: 550px;
        width: 232px;
        height: 212px;
        border-radius: 20px;
    }
    .callout-box-1-card {
        font-weight: 500;
        width: 282px;
        height: 202px;
        padding-top:0px;
        border-radius: 20px;
        background: linear-gradient(to bottom, #000000 0%,#333333 100%); 
    }
    .callout-box-1-pill {
        width: 8px;
        height: 110px;
        border-radius: 500px;
        float: left;
        margin-left:15px;
        margin-top:50px;
    
    }
    .callout-box-1-content {
        font-size: 30px;
        line-height: 30px;
        padding-top:55px;
        padding-left: 50px;
    }
    .callout-box-2 {
        margin-top: 550px;
        width: 292px;
        height: 212px;
        padding:20px;
        padding-top:60px;
        border-radius: 20px;
    }
    .callout-box-2-content {
        font-size: 30px;
        line-height: 30px;
    }
    .callout-box-3 {
        margin-top: 550px;
        grid-column: 5/6;
        width: 292px;
        height: 212px;
        border-radius: 20px;
    }
    .callout-box-3-card {
        width: 282px;
        height: 202px;
        border-radius: 20px;
        padding:20px;
        padding-top:35px;
        background: linear-gradient(to bottom, #000000 0%,#333333 100%); 
        margin-top:10px;
        margin-left:10px;
    }
    .callout-box-3-content {
        font-size: 30px;
        line-height: 30px;
    }
    .barrows-dot, .walmart-dot, .shoprite-dot, .tesco-dot{
        background-size: auto;
        min-height: 1100px;
    }
    .section-headline {
        margin-left:50px;
        margin-right: 50px;
    }
    .sub-section-headline {
        font-size: 1.25em;
    }
    .rmn-quote-block {
        width: 100%;
    }
    .rmn-overview {
        margin:25px;
        margin-top:50px;
        width: 100%;
        font-size: .8em;
    }
    .rmn-col-1-content {
        font-size: .8em;
    }
    .rmn-col-2-content {
        font-size: .8em;
    }
    .rmn20-section {
        min-height: 2400px;
    }
    .numbered-image-bubble-right, .numbered-image-bubble-left {
        width: 50px;
        height: 50px;
        font-size: .8em;
    }
    .metric-top-number {
        font-size: 125px;
    }
    .metric-bottom-text {
        font-size: 22px;
        line-height: 60px;
    }
    .partner-metrics {
        height: 220px;
    }

}
@media only screen and (min-width: 1280px) and (max-width: 1799px) {
    .header-grid {
        grid-template-columns: repeat(2, 1fr) repeat(3, 354px) repeat(2, 1fr);
        grid-column-gap: 35px;

    }
    .headline-content {
        margin-top:125px;
        grid-column: 3/6;
        font-weight: 900;
        font-size: 55px;
        line-height: 55px;
    }
    .callout-box-1 { 
        margin-top: 550px;
        width: 354px;
        height: 257px;
        border-radius: 20px;
    }
    .callout-box-1-card {
        font-weight: 500;
        width: 342px;
        height: 244px;
        border-radius: 20px;
        background: linear-gradient(to bottom, #000000 0%,#333333 100%); 
    }
    .callout-box-1-pill {
        width: 8px;
        height: 140px;
        border-radius: 500px;
        float: left;
        margin-left:15px;
        margin-top:55px;
    
    }
    .callout-box-1-content {
        font-size: 37px;
        line-height: 37px;
        padding-top:70px;
        padding-left: 50px;
    }
    .callout-box-2 {
        margin-top: 550px;
        grid-column: 4/5;
        width: 354px;
        height: 257px;
        padding:30px;
        padding-top:65px;
        border-radius: 20px;
    }
    .callout-box-2-content {
        font-size: 37px;
        line-height: 37px;
    }
    .callout-box-3 {
        margin-top: 550px;
        grid-column: 5/6;
        width: 354px;
        height: 257px;
        border-radius: 20px;
    }
    .callout-box-3-card {
        width: 342px;
        height: 244px;
        border-radius: 20px;
        padding:30px;
        padding-top:40px;
        background: linear-gradient(to bottom, #000000 0%,#333333 100%); 
        margin-top:12px;
        margin-left:12px;
    }
    .callout-box-3-content {
        font-size: 37px;
        line-height: 37px;
    }
    .barrows-dot, .walmart-dot, .shoprite-dot, .tesco-dot{
        background-size: auto;
        min-height: 1150px;
    }
    .section-headline {
        margin-left:50px;
        margin-right: 50px;
    }
    .sub-section-headline {
        font-size: 1.25em;
    }
    .rmn-quote-block {
        width: 80%;
    }
    .rmn-overview {
        margin:25px;
        margin-top:50px;
        width: 100%;
        font-size: .8em;
    }
    .rmn-col-1-content {
        font-size: .8em;
    }
    .rmn-col-2-content {
        font-size: .8em;
    }
    .rmn20-section {
        min-height: 2400px;
    }
    .numbered-image-bubble-right, .numbered-image-bubble-left {
        width: 50px;
        height: 50px;
        font-size: .8em;
    }
    .metric-top-number {
        font-size: 125px;
    }
    .metric-bottom-text {
        font-size: 22px;
        line-height: 60px;
    }
    .partner-metrics {
        height: 220px;
    }
}
@media only screen and (min-width: 1400px) and (max-width: 1800px) {
    
    
    .numbered-image-bubble-right, .numbered-image-bubble-left {
        width: 50px;
        height: 50px;
        font-size: .8em;
    }

    
    
}
@media only screen and (min-width: 1800px)  {

    .header-grid {
        grid-column-gap: 100px;
    }
    .headline-content {
        margin-top:125px;
        grid-column: 2/6;
        font-weight: 900;
        font-size: 60px;
        line-height: 60px;
    }
    .callout-box-1 {
        margin-top: 750px;
        width: 390px;
        height: 310px;
        border-radius: 20px;
    }
    .callout-box-1-card {
        font-weight: 500;
        width: 380px;
        height: 300px;
        border-radius: 20px;
        background: linear-gradient(to bottom, #000000 0%,#333333 100%); 
    }
    .callout-box-1-pill {
        width: 10px;
        height: 200px;
        border-radius: 500px;
        float: left;
        margin-left:15px;
        margin-top:55px;
    
    }
    .callout-box-1-content {
        font-size: 40px;
        line-height: 40px;
        padding-top:100px;
        padding-left: 50px;
    }
    .callout-box-2 {
        margin-top: 750px;
        width: 390px;
        height: 310px;
        padding:30px;
        padding-top:100px;
        border-radius: 20px;
    }
    .callout-box-2-content {
        font-size: 40px;
        line-height: 40px;
    }
    .callout-box-3 {
        margin-top: 750px;
        width: 390px;
        height: 310px;
        border-radius: 20px;
    }
    .callout-box-3-card {
        width: 380px;
        height: 300px;
        border-radius: 20px;
        padding:30px;
        padding-top:80px;
        background: linear-gradient(to bottom, #000000 0%,#333333 100%); 
        margin-top:12px;
        margin-left:12px;
    }
    .callout-box-3-content {
        font-size: 40px;
        line-height: 40px;
    }
    .rmn20-section {
        min-height: 3000px;
    }
   
}


*/

/*


*/
